p {
    text-align: justify;
}
p ul {
    display: block;
    padding-left: 1.5rem;
}
p li:before {
    content: "•";
    color: #000;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
}
